import { AppConfig } from 'models/AppConfig';

function validateHasValue(value: string, name: string) {
  return value != null && name != null;
}

function buildString(value: string, name: string): string {
  validateHasValue(value, name);

  return value;
}

function buildBoolean(value: string, name: string) {
  validateHasValue(value, name);

  return value === 'true';
}

//eslint-disable-next-line @typescript-eslint/no-unused-vars
function getValue<T>(envValue: T, accessor: () => T): T {
  return process.env.NODE_ENV !== 'production' ? envValue : accessor();
}

export function buildConfig(jsonValue: any): AppConfig {
  return {
    api: {
      apiUrl: getValue(buildString(process.env.REACT_APP_API_URL || '', 'REACT_APP_API_URL'), () =>
        buildString(jsonValue.REACT_APP_API_URL, 'REACT_APP_API_URL'),
      ),
      adminApiUrl: getValue(buildString(process.env.REACT_APP_ADMIN_API_URL || '', 'REACT_APP_ADMIN_API_URL'), () =>
        buildString(jsonValue.REACT_APP_ADMIN_API_URL, 'REACT_APP_ADMIN_API_URL'),
      ),
      marketplaceApiUrl: getValue(
        buildString(process.env.REACT_APP_MARKETPLACE_API_URL || '', 'REACT_APP_MARKETPLACE_API_URL'),
        () => buildString(jsonValue.REACT_APP_API_DOMAIN, 'REACT_APP_MARKETPLACE_API_URL'),
      ),
      publicApiUrl: getValue(buildString(process.env.REACT_APP_PUBLIC_API_URL || '', 'REACT_APP_PUBLIC_API_URL'), () =>
        buildString(jsonValue.REACT_APP_PUBLIC_API_URL, 'REACT_APP_PUBLIC_API_URL'),
      ),
    },
    appSettings: {
      brandKey: getValue(buildString(process.env.REACT_APP_BRAND_KEY || 'EXP', 'REACT_APP_BRAND_KEY'), () =>
        buildString(jsonValue.REACT_APP_BRAND_KEY, 'REACT_APP_BRAND_KEY'),
      ),
      partnerKey: getValue(buildString(process.env.REACT_APP_PARTNER_KEY || 'EXP', 'REACT_APP_PARTNER_KEY'), () =>
        buildString(jsonValue.REACT_APP_PARTNER_KEY, 'REACT_APP_PARTNER_KEY'),
      ),
      apiDomain: getValue(buildString(process.env.REACT_APP_API_DOMAIN || '', 'REACT_APP_API_DOMAIN'), () =>
        buildString(jsonValue.REACT_APP_API_DOMAIN, 'REACT_APP_API_DOMAIN'),
      ),
      prodMode: getValue(buildBoolean(process.env.REACT_APP_PROD_MODE || 'false', 'REACT_APP_PROD_MODE'), () =>
        buildBoolean(jsonValue.REACT_APP_PROD_MODE, 'REACT_APP_PROD_MODE'),
      ),
      isMaintenanceMode: getValue(
        buildBoolean(process.env.REACT_APP_IS_MAINTENANCE_MODE || 'false', 'REACT_APP_IS_MAINTENANCE_MODE'),
        () => buildBoolean(jsonValue.REACT_APP_IS_MAINTENANCE_MODE, 'REACT_APP_IS_MAINTENANCE_MODE'),
      ),
      applicationInsightsKey: getValue(
        buildString(process.env.REACT_APP_APPLICATION_INSIGHTS_IK || '', 'REACT_APP_APPLICATION_INSIGHTS_IK'),
        () => buildString(jsonValue.REACT_APP_APPLICATION_INSIGHTS_IK, 'REACT_APP_APPLICATION_INSIGHTS_IK'),
      ),
      applicationContextToken: getValue(
        buildString(process.env.REACT_APP_APPLICATION_CONTEXT_TOKEN || '', 'REACT_APP_APPLICATION_CONTEXT_TOKEN'),
        () => buildString(jsonValue.REACT_APP_APPLICATION_CONTEXT_TOKEN, 'REACT_APP_APPLICATION_CONTEXT_TOKEN'),
      ),
      publicUrl: getValue(buildString(process.env.REACT_APP_PUBLIC_URL || '', 'REACT_APP_PUBLIC_URL'), () =>
        buildString(jsonValue.REACT_APP_PUBLIC_URL, 'REACT_APP_PUBLIC_URL'),
      ),
      enablePriceComparison: getValue(
        buildBoolean(process.env.REACT_APP_ENABLE_PRICE_COMPARISON || 'false', 'REACT_APP_ENABLE_PRICE_COMPARISON'),
        () => buildBoolean(jsonValue.REACT_APP_ENABLE_PRICE_COMPARISON, 'REACT_APP_ENABLE_PRICE_COMPARISON'),
      ),
      defaultStateToShip: getValue(
        buildString(process.env.REACT_APP_DEFAULT_STATE_TO_SHIP || 'CA', 'REACT_APP_DEFAULT_STATE_TO_SHIP'),
        () => buildString(jsonValue.REACT_APP_DEFAULT_STATE_TO_SHIP, 'REACT_APP_DEFAULT_STATE_TO_SHIP'),
      ),
      materialUiLicense: getValue(
        buildString(process.env.REACT_APP_MATERIAL_UI_LICENSE || '', 'REACT_APP_MATERIAL_UI_LICENSE'),
        () => buildString(jsonValue.REACT_APP_MATERIAL_UI_LICENSE, 'REACT_APP_MATERIAL_UI_LICENSE'),
      ),
      hideCreateAccountBenefits: getValue(
        buildBoolean(
          process.env.REACT_APP_HIDE_CREATE_ACCOUNT_BENEFITS || '',
          'REACT_APP_HIDE_CREATE_ACCOUNT_BENEFITS',
        ),
        () => buildBoolean(jsonValue.REACT_APP_HIDE_CREATE_ACCOUNT_BENEFITS, 'REACT_APP_HIDE_CREATE_ACCOUNT_BENEFITS'),
      ),
      enableSocialLogins: getValue(
        buildBoolean(process.env.REACT_APP_ENABLE_SOCIAL_LOGINS || '', 'REACT_APP_ENABLE_SOCIAL_LOGINS'),
        () => buildBoolean(jsonValue.REACT_APP_ENABLE_SOCIAL_LOGINS, 'REACT_APP_ENABLE_SOCIAL_LOGINS'),
      ),
    },
    hubSpot: {
      hubspotPortalId: getValue(
        buildString(process.env.REACT_APP_HUBSPOT_PORTAL_ID || '', 'REACT_APP_HUBSPOT_PORTAL_ID'),
        () => buildString(jsonValue.REACT_APP_HUBSPOT_PORTAL_ID, 'REACT_APP_HUBSPOT_PORTAL_ID'),
      ),
      hubspotFormId: getValue(
        buildString(process.env.REACT_APP_HUBSPOT_FORM_ID || '', 'REACT_APP_HUBSPOT_FORM_ID'),
        () => buildString(jsonValue.REACT_APP_HUBSPOT_FORM_ID, 'REACT_APP_HUBSPOT_FORM_ID'),
      ),
      hubspotRegion: getValue(buildString(process.env.REACT_APP_HUBSPOT_REGION || '', 'REACT_APP_HUBSPOT_REGION'), () =>
        buildString(jsonValue.REACT_APP_HUBSPOT_REGION, 'REACT_APP_HUBSPOT_REGION'),
      ),
      hubspotFormUrl: getValue(
        buildString(process.env.REACT_APP_HUBSPOT_FORM_URL || '', 'REACT_APP_HUBSPOT_FORM_URL'),
        () => buildString(jsonValue.REACT_APP_HUBSPOT_FORM_URL, 'REACT_APP_HUBSPOT_FORM_URL'),
      ),
    },
    google: {
      googleMapsKey: getValue(
        buildString(process.env.REACT_APP_GOOGLE_MAPS_KEY || '', 'REACT_APP_GOOGLE_MAPS_KEY'),
        () => buildString(jsonValue.REACT_APP_GOOGLE_MAPS_KEY, 'REACT_APP_GOOGLE_MAPS_KEY'),
      ),
      geolocationUrl: getValue(
        buildString(process.env.REACT_APP_GEOLOCATION_URL || '', 'REACT_APP_GEOLOCATION_URL'),
        () => buildString(jsonValue.REACT_APP_GEOLOCATION_URL, 'REACT_APP_GEOLOCATION_URL'),
      ),
      geolocationKey: getValue(
        buildString(process.env.REACT_APP_GEOLOCATION_KEY || '', 'REACT_APP_GEOLOCATION_KEY'),
        () => buildString(jsonValue.REACT_APP_GEOLOCATION_KEY, 'REACT_APP_GEOLOCATION_KEY'),
      ),
    },
    analytics: {
      hubspotUrl: getValue(buildString(process.env.REACT_APP_HUBSPOT_URL || '', 'REACT_APP_HUBSPOT_URL'), () =>
        buildString(jsonValue.REACT_APP_HUBSPOT_URL, 'REACT_APP_HUBSPOT_URL'),
      ),
      gaTrackingKey: getValue(buildString(process.env.REACT_APP_GA_TRACKING_ID || '', 'REACT_APP_GA_TRACKING_ID'), () =>
        buildString(jsonValue.REACT_APP_GA_TRACKING_ID, 'REACT_APP_GA_TRACKING_ID'),
      ),
    },
    princessConfig: {
      iframeSignInUrl: getValue(
        buildString(process.env.REACT_APP_PRINCESS_IFRAME_SIGNIN_URL || '', 'REACT_APP_PRINCESS_IFRAME_SIGNIN_URL'),
        () => buildString(jsonValue.REACT_APP_PRINCESS_IFRAME_SIGNIN_URL, 'REACT_APP_PRINCESS_IFRAME_SIGNIN_URL'),
      ),
      iframeSignInEnabled: getValue(
        buildBoolean(
          process.env.REACT_APP_PRINCESS_IFRAME_SIGNIN_ENABLED || 'false',
          'REACT_APP_PRINCESS_IFRAME_SIGNIN_ENABLED',
        ),
        () =>
          buildBoolean(jsonValue.REACT_APP_PRINCESS_IFRAME_SIGNIN_ENABLED, 'REACT_APP_PRINCESS_IFRAME_SIGNIN_ENABLED'),
      ),
      isSingleIdentityEnabled: getValue(
        buildBoolean(
          process.env.REACT_APP_ENABLE_MCM_SINGLE_IDENTITY || 'false',
          'REACT_APP_ENABLE_MCM_SINGLE_IDENTITY',
        ),
        () => buildBoolean(jsonValue.REACT_APP_ENABLE_MCM_SINGLE_IDENTITY, 'REACT_APP_ENABLE_MCM_SINGLE_IDENTITY'),
      ),
    },
    featuredProducts: {
      featureMerchID: getValue(buildString(process.env.REACT_APP_FEATURE_MERCH || '', 'REACT_APP_FEATURE_MERCH'), () =>
        buildString(jsonValue.REACT_APP_FEATURE_MERCH, 'REACT_APP_FEATURE_MERCH'),
      ),
      featureWineID: getValue(buildString(process.env.REACT_APP_FEATURE_WINE || '', 'REACT_APP_FEATURE_WINE'), () =>
        buildString(jsonValue.REACT_APP_FEATURE_WINE, 'REACT_APP_FEATURE_WINE'),
      ),
      featuredCruiseIDs: {
        featureCruise1: getValue(
          buildString(process.env.REACT_APP_FEATURE_CRUISE1 || '', 'REACT_APP_FEATURE_CRUISE1'),
          () => buildString(jsonValue.REACT_APP_FEATURE_CRUISE1, 'REACT_APP_FEATURE_CRUISE1'),
        ),
        featureCruise2: getValue(
          buildString(process.env.REACT_APP_FEATURE_CRUISE2 || '', 'REACT_APP_FEATURE_CRUISE2'),
          () => buildString(jsonValue.REACT_APP_FEATURE_CRUISE2, 'REACT_APP_FEATURE_CRUISE2'),
        ),
        featureCruise3: getValue(
          buildString(process.env.REACT_APP_FEATURE_CRUISE3 || '', 'REACT_APP_FEATURE_CRUISE3'),
          () => buildString(jsonValue.REACT_APP_FEATURE_CRUISE3, 'REACT_APP_FEATURE_CRUISE3'),
        ),
        featureCruise4: getValue(
          buildString(process.env.REACT_APP_FEATURE_CRUISE4 || '', 'REACT_APP_FEATURE_CRUISE4'),
          () => buildString(jsonValue.REACT_APP_FEATURE_CRUISE4, 'REACT_APP_FEATURE_CRUISE4'),
        ),
        featureCruise5: getValue(
          buildString(process.env.REACT_APP_FEATURE_CRUISE5 || '', 'REACT_APP_FEATURE_CRUISE5'),
          () => buildString(jsonValue.REACT_APP_FEATURE_CRUISE5, 'REACT_APP_FEATURE_CRUISE5'),
        ),
        featureCruise6: getValue(
          buildString(process.env.REACT_APP_FEATURE_CRUISE6 || '', 'REACT_APP_FEATURE_CRUISE6'),
          () => buildString(jsonValue.REACT_APP_FEATURE_CRUISE6, 'REACT_APP_FEATURE_CRUISE6'),
        ),
      },
      featuredRiverCruiseIDs: {
        featureCruise1: getValue(
          buildString(process.env.REACT_APP_FEATURE_RIVER_CRUISE1 || '', 'REACT_APP_FEATURE_RIVER_CRUISE1'),
          () => buildString(jsonValue.REACT_APP_FEATURE_RIVER_CRUISE1, 'REACT_APP_FEATURE_RIVER_CRUISE1'),
        ),
        featureCruise2: getValue(
          buildString(process.env.REACT_APP_FEATURE_RIVER_CRUISE2 || '', 'REACT_APP_FEATURE_RIVER_CRUISE2'),
          () => buildString(jsonValue.REACT_APP_FEATURE_RIVER_CRUISE2, 'REACT_APP_FEATURE_RIVER_CRUISE2'),
        ),
        featureCruise3: getValue(
          buildString(process.env.REACT_APP_FEATURE_RIVER_CRUISE3 || '', 'REACT_APP_FEATURE_RIVER_CRUISE3'),
          () => buildString(jsonValue.REACT_APP_FEATURE_RIVER_CRUISE3, 'REACT_APP_FEATURE_RIVER_CRUISE3'),
        ),
      },
      featuredResortIDs: {
        featureResort1: getValue(
          buildString(process.env.REACT_APP_FEATURE_RESORT1 || '', 'REACT_APP_FEATURE_RESORT1'),
          () => buildString(jsonValue.REACT_APP_FEATURE_RESORT1, 'REACT_APP_FEATURE_RESORT1'),
        ),
        featureResort2: getValue(
          buildString(process.env.REACT_APP_FEATURE_RESORT2 || '', 'REACT_APP_FEATURE_RESORT2'),
          () => buildString(jsonValue.REACT_APP_FEATURE_RESORT2, 'REACT_APP_FEATURE_RESORT2'),
        ),
        featureResort3: getValue(
          buildString(process.env.REACT_APP_FEATURE_RESORT3 || '', 'REACT_APP_FEATURE_RESORT3'),
          () => buildString(jsonValue.REACT_APP_FEATURE_RESORT3, 'REACT_APP_FEATURE_RESORT3'),
        ),
        featureResort4: getValue(
          buildString(process.env.REACT_APP_FEATURE_RESORT4 || '', 'REACT_APP_FEATURE_RESORT4'),
          () => buildString(jsonValue.REACT_APP_FEATURE_RESORT4, 'REACT_APP_FEATURE_RESORT4'),
        ),
      },
    },
  };
}
